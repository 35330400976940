import React from 'react'

import { Wrapper, P, H1, Section, BoldP } from './styled'

const About = _ => (
  <Wrapper name='about'>
    <Section>
      <H1>A little bit about myself</H1>
      <BoldP>My name is Quinton Bolt and I am a software engineer living in Memphis, Tennessee.</BoldP>
      <P>I have a passion for crafting delightful and efficient solutions to interesting problems. My current areas of interest are in mobile development, cybersecurity, GraphQL, and functional programming, but my expertise extends to a variety of technologies and stacks in software development. In my work, I've been focused on building full-stack applications using technologies such as React, GraphQL, Node, Go, Python, Java with Spring, .NET, as well as mobile development with React Native and Swift.</P>
      <P>When I'm not working, I like to stay up to date with the industry by reading books, participating in local meetups, and by helping newcomers break into the industry. I strive to be a life-long learner and I enjoy paying it forward by sharing those learnings with others.</P>
      <P>In my free time, I enjoy hiking, kayaking, singing in various choirs in my area, and board games with friends.</P>
    </Section>
  </Wrapper>
)

export default About
