import React from 'react'

import { Bounce, StyledLink } from './styled'

const DownArrow = (props) => (
  <StyledLink {...props}>
    <Bounce active={props.active}>
      <i className="fas fa-arrow-down"/>
    </Bounce>
  </StyledLink>
)

export default DownArrow
