import styled from 'styled-components'

export const Wrapper = styled.div`
  justify-content: center;
  padding: 48px 12px;
`

export const H1 = styled.h1`
  font-size: 3em;
  width: fit-content;
`

export const IFrame = styled.iframe`
  width: 100%;
  height: 1020px;
  
  @media (max-width: 767px) {
    display: none;
  }
`

export const ResumeLink = styled.a`
  background-color: ${props => props.theme.secondary};
  border-radius: 5px;
  color: white;
  padding: .5em;
  text-decoration: none;
  transition: background-color 0.3s ease;
  
  &:hover {
    background-color: ${props => props.theme.accent};
  }
`
