import styled, { css } from 'styled-components'
import { Link } from 'react-scroll'

export const StickyHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100vw;
  
  background-color: ${props => props.theme.background};
  height: 48px;
  z-index: 100;
`

export const Nav = styled.nav`
  margin-left: 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  
  width: 100%;
  max-width: 70%;

  @media (min-width: 768px) {
    max-width: 30%;
  }
`

const NavStyle = css`
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;

  &:after {
    display: block;
    content: '';
    border-bottom: 2px solid ${props => props.active ? props.theme.accent : 'transparent'};  
    transform: ${props => props.active ? 'scaleX(1)' : 'scaleX(0)'};  
  }
  
  &:hover:after { 
    border-bottom: 2px solid ${props => props.active ? props.theme.accent : props.theme.secondary};
    transform: scaleX(1);
    transition: transform 250ms ease-in;
  }
  
  &:hover {
    cursor: pointer;
  }
`

export const StyledNavLink = styled(Link)`
  ${NavStyle}
`

export const StyledNavA = styled.a`
  cursor: pointer;
  color: ${props => props.theme.primary};
  text-decoration: none;
  ${NavStyle}
`

export const Logo = styled(StyledNavLink)`
  font-weight: bolder;
  font-size: 1.2em;
  line-height: 20px;
  color: ${props => props.theme.secondary};
  
  &:hover {
    cursor: pointer;
  }
`
