import React from 'react'
import { resumePreview } from '../../constants'

import { Wrapper, H1, IFrame } from './styled'

const Resume = _ => (
  <Wrapper name='resume'>
    <H1>Resume</H1>
    <IFrame src={resumePreview}/>
  </Wrapper>
)

export default Resume
