import React, { Component } from 'react'
import styled, { ThemeProvider } from 'styled-components'

import theme from './theme'

import Header from './components/Header'
import Home from './views/Home'
import About from './views/About'
import Resume from './views/Resume'
import { Desktop } from './styles/shared'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: ${props => props.theme.primary};
  background-color: ${props => props.theme.background};
`

const Main = styled.div`
  max-width: 768px;
  margin: auto;
`

class App extends Component {
  state = {
    active: 'home'
  }

  setActive = (active) => {
    this.setState({ active })
  }

  render () {
    return (
      <ThemeProvider theme={theme}>
        <Wrapper>
          <Header setActive={this.setActive} active={this.state.active}/>
          <Main>
            <Home setActive={this.setActive} active={this.state.active}/>
            <About setActive={this.setActive} active={this.state.active}/>
            <Desktop>
              <hr/>
              <Resume active={this.state.active}/>
            </Desktop>
          </Main>
        </Wrapper>
      </ThemeProvider>

    )
  }
}

export default App
