import styled from 'styled-components'
import DownArrow from '../../components/DownArrow'

export const AboutArrow = styled(DownArrow)`
  padding-bottom: 60px;
`

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  
  padding-top: 40px;
`

export const Img = styled.img`
  width: 120px;
  height: 120px;
  border-radius: 25%;
  box-shadow: 0 0 5px 1px rgba(0,0,0,.35);
`

export const Name = styled.h1`
  font-size: 42px;
`

export const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`

export const ContactContainer = styled.div`
  display: flex;
  flex-direction: row;
`

export const ContactLink = styled.a`
  color: ${props => props.theme.secondary};
  font-size: 24px;
  margin: 12px;
  
  transition: color 0.3s ease;
  &:hover {
    color: ${props => props.theme.accent};
  }
`
