import styled from 'styled-components'

export const Desktop = styled.div`
  @media (max-width: 767px) {
    display: none;
  }
`

export const Mobile = styled.div`
  display: flex;
  justify-content: center;
  @media (min-width: 768px) {
    display: none;
  }
`
