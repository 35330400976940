import React from 'react'

import { Wrapper, Main, Name, ContactContainer, ContactLink, AboutArrow } from './styled'
import Portrait from './Portrait'

const Home = props => (
  <Wrapper name='home'>
    <div/>
    <Main>
      <Portrait/>
      <Name>Quinton Bolt</Name>
      <h3>Engineer &bull; Musician &bull; Teacher</h3>
      <ContactContainer>
        <ContactLink href="mailto:bolt.quinton@gmail.com">
          <i className="far fa-envelope"/>
        </ContactLink>
        <ContactLink href="https://www.linkedin.com/in/quinton-bolt-66a849124/">
          <i className="fab fa-linkedin-in"/>
        </ContactLink>
        <ContactLink href="https://www.github.com/qbolt">
          <i className="fab fa-github"/>
        </ContactLink>
      </ContactContainer>
    </Main>
    <AboutArrow
      spy
      smooth
      onClick={() => props.setActive('about')}
      active={props.active === 'home'}
      to='about'
      offset={8}
      duration={500}
    />
  </Wrapper>
)

export default Home
