import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
    justify-content: space-between;

  align-items: center;  
  padding: 48px 12px;
`

export const H1 = styled.h1`
  font-size: 3em;
  width: fit-content;
`

export const P = styled.p`
  font-size: 1.2em;
  
  @media (min-width: 768px) {
    font-size: 2em;
  }
`

export const BoldP = styled(P)`
  font-weight: 600;
`

export const Section = styled.section`
  display: flex;
  flex-direction: column;
`
