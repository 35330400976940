import React from 'react'

import { StickyHeader, StyledNavLink, Nav, Logo, StyledNavA } from './styled'
import { resumeLink } from '../../constants'
import { Desktop, Mobile } from '../../styles/shared'

const Header = props => (
  <StickyHeader>
    <Nav>
      <StyledNavLink
        to='about'
        onClick={() => props.setActive('about')}
        onSetActive={() => props.setActive('about')}
        active={props.active === 'about'}
        offset={30}
        smooth
        spy
        duration={500}
      >
        about
      </StyledNavLink>
      |
      <Logo
        to='home'
        onClick={() => props.setActive('home')}
        onSetActive={() => props.setActive('home')}
        active={props.active === 'home'}
        smooth
        spy
        duration={300}
      >
        QB
      </Logo>
      |
      <Desktop>
        <StyledNavLink
          to='resume'
          onClick={() => props.setActive('resume')}
          onSetActive={() => props.setActive('resume')}
          active={props.active === 'resume'}
          offset={8}
          smooth
          spy
          duration={500}
        >
          resume
        </StyledNavLink>
      </Desktop>
      <Mobile>
        <StyledNavA href={resumeLink}>resume</StyledNavA>
      </Mobile>
    </Nav>
  </StickyHeader>
)

export default Header
