import styled, { keyframes } from 'styled-components'
import { Link } from 'react-scroll'

const bounceAnimation = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
`

export const Bounce = styled.div`
  transition: color .3s ease, font-weight 0.3s ease;
  font-size: 24px;
  text-align: center;
  
  -moz-animation: ${props => props.active ? bounceAnimation : 'none'} 2s infinite;
  -webkit-animation: ${props => props.active ? bounceAnimation : 'none'} 2s infinite;
  animation: ${props => props.active ? bounceAnimation : 'none'} 2s infinite;
  
  &:hover {
  -moz-animation: none;
  -webkit-animation: none;
  animation: none;
  }
`

export const StyledLink = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding-top: 20px;
  width: 60px;
  height: 60px;
  cursor: pointer;
  color: ${props => props.theme.secondary};
  &:hover {
    color: ${props => props.theme.accent};
    font-weight: bold;
  }
`
